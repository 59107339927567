import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from 'styleguide';
import { WorkOrderStatus } from '../../constants/classifierConstants';
import { showWarning } from '../../stores/notification';
import { resetSubmitted, saveDeadline, setDeleted, setInvalid, takeForFulfillment, setCompleted, sendForRevision, handleWorkOrderDone, setDetails } from '../../stores/workOrder/workOrder';
import DateUtils from '../../utils/DateUtils';
import ActivityLogTable from '../table/ActivityLogTable';
import ConfirmButton, { ConfirmButtonWithReason } from '../table/ConfirmButton';
import { api } from '../../api/workOrder';
import AuthUtils from '../../utils/AuthUtils';
import { Authority } from '../../constants/authConstants';
import ConfirmButtonWithDeadline from '../form/ConfirmButtonWithDeadline';

export default function WorkOrderActions() {
  const { t } = useTranslation();
  const { selected, submitted, isLoading } = useSelector(state => state.workOrder);
  const { authUser } = useSelector(state => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();

  const domain = selected.domain;
  useEffect(() => {
    if (submitted) {
      dispatch(resetSubmitted());
      history.push(`/workOrder/${domain}`);
    }
  }, [dispatch, history, submitted, domain]);

  const handleEdit = () => {
    history.push(`/workOrder/edit/${selected.id}`);
  };

  const handleCopy = () => {
    dispatch(setDetails({
      domain: selected.domain,
      relatedTable: selected.relatedTable,
      relatedId: selected.relatedId,
      source: selected.source,
      description: selected.description,
      geometry: selected.geometry,
      location: selected.location
    }));
    history.replace('/workOrder/edit');
  };

  const handleSendForFulfillment = () => {
    let valid = true;
    if (!selected.geometry) {
      dispatch(showWarning('validation.geometryRequired', { field: t('workOrder.domain') }));
      valid = false;
    }
    if (!selected.domain) {
      dispatch(showWarning('validation.requiredField', { field: t('workOrder.domain') }));
      valid = false;
    }
    if (!selected.description) {
      dispatch(showWarning('validation.requiredField', { field: t('workOrder.description') }));
      valid = false;
    }
    if (!selected.deadline) {
      dispatch(showWarning('validation.requiredField', { field: t('workOrder.deadline') }));
      valid = false;
    }
    if (!selected.userFulfiller && !selected.cooperationPartnerCompany) {
      dispatch(showWarning('validation.requiredField', { field: t('workOrder.fulfiller') }));
      valid = false;
    }
    if (valid) {
      dispatch(handleWorkOrderDone(selected));
    } else {
      handleEdit();
    }
  };

  const handleDelete = () => {
    dispatch(setDeleted(selected.id));
  };

  const handleInvalidate = (reason) => {
    dispatch(setInvalid(selected.id, reason));
  };

  const handleChangeDeadline = (reason, deadline) => {
    dispatch(saveDeadline(selected.id, DateUtils.formatISODateTime(deadline), reason));
  };

  const handleSetCompleted = () => {
    dispatch(setCompleted(selected.id));
  };

  const handleSendForRevision = (reason, deadline) => {
    dispatch(sendForRevision(selected.id, reason, DateUtils.formatISODateTime(deadline)));
  };

  const handleTakeForFulfillment = () => {
    dispatch(takeForFulfillment(selected.id));
  };

  const handleFulfill = () => {
    history.push(`/workOrder/fulfil/${selected.id}`);
  };

  const isAuthor = selected.userAuthor?.id === authUser.id || AuthUtils.isSubstituter(authUser, selected.userAuthor?.id);
  const isFulfiller = (selected.userFulfiller?.id === authUser.id || AuthUtils.isSubstituter(authUser, selected.userFulfiller?.id)) ||
    AuthUtils.hasAuthority(authUser, Authority.UC27_partner_work_order);

  const fulfillButton = <Button onClick={handleFulfill} disabled={isLoading}>{t('workOrder.view.setFulfilled')}</Button>;
  const invalidateButton =
    <ConfirmButtonWithReason message={t('workOrder.view.confirmSetInvalid')} btnText={t('workOrder.view.setInvalid')}
      color="tertiary" onConfirm={handleInvalidate}
      aria-label="invalidate work order" disabled={isLoading} />;
  const changeDeadlineButton =
    <ConfirmButtonWithDeadline message={t('workOrder.view.confirmChangeDeadline')} btnText={t('workOrder.view.changeDeadline')}
      color="secondary" onConfirm={handleChangeDeadline} defaultDeadline={selected.deadline}
      aria-label="change work order deadline" disabled={isLoading} withTime />;

  let mainActions = [];
  let secondaryActions = [];
  if (isAuthor) {
    switch (selected.status) {
      case WorkOrderStatus.entered:
        mainActions.push(<Button onClick={handleSendForFulfillment} disabled={isLoading}>{t('workOrder.view.sendForFulfillment')}</Button>);
        secondaryActions.push(<Button onClick={handleEdit} color="secondary" disabled={isLoading}>{t('button.edit')}</Button>);
        secondaryActions.push(
          <ConfirmButton message={t('form.confirmDelete')} onConfirm={handleDelete} btnText={t('button.delete')} color="tertiary"
            aria-label="delete work order" disabled={isLoading} />
        );
        break;
      case WorkOrderStatus.open:
        mainActions.push(fulfillButton);
        secondaryActions.push(
          <ConfirmButtonWithReason message={t('workOrder.view.confirmSetInvalid')} btnText={t('workOrder.view.setInvalid')}
            color="tertiary" onConfirm={handleInvalidate}
            aria-label="invalidate work order" disabled={isLoading} />
        );
        secondaryActions.push(changeDeadlineButton);
        break;
      case WorkOrderStatus.takenForFulfillment:
        mainActions.push(fulfillButton);
        secondaryActions.push(invalidateButton);
        secondaryActions.push(changeDeadlineButton);
        break;
      default:
    };
  } else if (isFulfiller) {
    switch (selected.status) {
      case WorkOrderStatus.open:
        mainActions.push(<Button onClick={handleTakeForFulfillment} disabled={isLoading}>{t('workOrder.view.takeForFulfillment')}</Button>);
        mainActions.push(fulfillButton);
        break;
      case WorkOrderStatus.takenForFulfillment:
        mainActions.push(fulfillButton);
        break;
      default:
    }
  }

  if (AuthUtils.hasAuthority(authUser, Authority.UC26_work_orders)) {
    if(WorkOrderStatus.fulfilled === selected.status && (isAuthor || (!isFulfiller && domain === authUser.domain))){
      mainActions.push(<Button onClick={handleSetCompleted} disabled={isLoading}>{t('workOrder.view.setCompleted')}</Button>);
      secondaryActions.push(
        <ConfirmButtonWithDeadline message={t('workOrder.view.confirmSendForRevision')} btnText={t('workOrder.view.sendForRevision')}
          color="tertiary" onConfirm={handleSendForRevision} defaultDeadline={selected.deadline}
          aria-label="send work order for revision" disabled={isLoading} withTime />
      );
      secondaryActions.push(invalidateButton);
    }

    secondaryActions.push(<Button onClick={handleCopy} color="secondary" disabled={isLoading}>{t('button.copy')}</Button>);
  }

  const fetchLogsUrl = !!selected?.id && `workOrder/${selected.id}/log`;

  return <div>
    <Box paddingTop={2} paddingBottom={4}>
      <ActivityLogTable
        api={api}
        fetchLogsUrl={fetchLogsUrl}
        singleRowOpenable
      />
    </Box>

    <Grid container direction="row" spacing={4}>
      {mainActions.map((action, index) => <Grid key={index} item>{action}</Grid>)}
      <Box flexGrow={1}></Box>
      {secondaryActions.map((action, index) => <Grid key={index} item>{action}</Grid>)}
    </Grid>
  </div>;
}